<template>
  <div>
    <v-data-table
      :loading="dataTableLoading"
      :no-data-text="$t('dataTable.NO_DATA')"
      :no-results-text="$t('dataTable.NO_RESULTS')"
      :headers="headers"
      :items="items"
      :options.sync="pagination"
      :items-per-page="5"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props="{
        'items-per-page-text': $t('dataTable.ROWS_PER_PAGE'),
        'items-per-page-options': [5, 10, 25]
      }"
    >
      <template v-slot:top>
        <v-row>
          <v-col
            class="mt-3 pl-4"
            cols="12"
            sm="12"
            md="4"
          >
            <div class="text-left">
              <v-toolbar-title>{{ $t('users.TITLE') }}</v-toolbar-title>
            </div>
          </v-col>
          <v-col
            class="px-3"
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              id="search"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('dataTable.SEARCH')"
              single-line
              hide-details
              clearable
              clear-icon="mdi-close"
            />
          </v-col>
          <v-col
            class="text-right mb-2 mt-2 pr-2"
            cols="12"
            sm="6"
            md="4"
          >
            <ValidationObserver
              ref="observer"
              v-slot="{ invalid }"
              tag="form"
              @submit.prevent="submit()"
            >
              <v-dialog
                v-model="dialog"
                max-width="800px"
                content-class="dlgNewEditItem"
              >
                <template v-slot:activator="{ on }">
                  <div class="text-right">
                    <v-btn
                      color="secondary"
                      class="btnNewItem pr-4"
                      v-on="on"
                    >
                      <v-icon class="mr-2">
                        mdi-plus
                      </v-icon>
                      {{ $t('dataTable.NEW_ITEM') }}
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <template v-if="editedItem._id">
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <label for="createdAt">{{
                              $t('common.CREATED')
                            }}</label>
                            <div name="createdAt">
                              {{ getFormat(editedItem.createdAt) }}
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <label for="updatedAt">{{
                              $t('common.UPDATED')
                            }}</label>
                            <div name="updatedAt">
                              {{ getFormat(editedItem.updatedAt) }}
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <label for="verified">{{
                              $t('users.headers.VERIFIED')
                            }}</label>
                            <div
                              name="verified"
                              v-html="trueFalse(editedItem.verified)"
                            />
                          </v-col>
                        </template>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              id="name"
                              v-model="editedItem.name"
                              name="name"
                              :label="$t('users.headers.NAME')"
                              :error="errors.length > 0"
                              :error-messages="errors[0]"
                              autocomplete="off"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required|email"
                          >
                            <v-text-field
                              id="email"
                              v-model="editedItem.email"
                              name="email"
                              type="email"
                              :label="$t('users.headers.EMAIL')"
                              :error="errors.length > 0"
                              :error-messages="errors[0]"
                              autocomplete="off"
                            />
                          </ValidationProvider>
                        </v-col>
                        <template v-if="!editedItem._id">
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <ValidationProvider
                              v-slot="{ errors }"
                              rules="required|min:5"
                              vid="password"
                            >
                              <v-text-field
                                id="password"
                                key="password"
                                ref="password"
                                v-model="editedItem.password"
                                name="password"
                                type="password"
                                :label="$t('users.PASSWORD')"
                                :error="errors.length > 0"
                                :error-messages="errors[0]"
                                autocomplete="off"
                              />
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <ValidationProvider
                              v-slot="{ errors }"
                              rules="required|min:4|confirmed:password"
                            >
                              <v-text-field
                                id="confirmPassword"
                                key="confirmPassword"
                                v-model="editedItem.confirmPassword"
                                name="confirmPassword"
                                type="password"
                                :label="$t('users.CONFIRM_PASSWORD')"
                                :error="errors.length > 0"
                                :error-messages="errors[0]"
                                autocomplete="off"
                              />
                            </ValidationProvider>
                          </v-col>
                        </template>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-select
                              id="role"
                              v-model="editedItem.role"
                              clearable
                              name="role"
                              :items="roles"
                              item-text="name"
                              item-value="value"
                              :label="$t('users.headers.ROLE')"
                              :error="errors.length > 0"
                              :error-messages="errors[0]"
                              class="inputRole"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-autocomplete
                              id="city"
                              v-model="editedItem.city"
                              name="city"
                              :label="$t('users.headers.CITY')"
                              :search-input.sync="searchInput"
                              :items="allCities"
                              clearable
                              :error="errors.length > 0"
                              :error-messages="errors[0]"
                              autocomplete="off"
                              class="inputCity"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              id="country"
                              v-model="editedItem.country"
                              name="country"
                              :label="$t('users.headers.COUNTRY')"
                              :error="errors.length > 0"
                              :error-messages="errors[0]"
                              autocomplete="off"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              id="phone"
                              v-model="editedItem.phone"
                              name="phone"
                              type="tel"
                              :label="$t('users.headers.PHONE')"
                              :error="errors.length > 0"
                              :error-messages="errors[0]"
                              autocomplete="off"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="red lighten3"
                      text
                      class="btnCancel"
                      @click="close"
                    >
                      {{ $t('dataTable.CANCEL') }}
                    </v-btn>
                    <v-btn
                      color="green"
                      text
                      :disabled="invalid"
                      class="btnSave"
                      @click="save"
                    >
                      {{ $t('dataTable.SAVE') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </ValidationObserver>
          </v-col>
        </v-row>
      </template>
      <template v-slot:items="props">
        <td>{{ props.item.name }}</td>
        <td>{{ props.item.email }}</td>
        <td>{{ roleName(props.item.role) }}</td>
        <td v-html="trueFalse(props.item.verified)" />
        <td>{{ props.item.city }}</td>
        <td>{{ props.item.country }}</td>
        <td>{{ props.item.phone }}</td>
      </template>
      <template v-slot:[`item._id`]="{ item }">
        <td class="fill-height px-0">
          <v-row class="justify-center">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  id="edit"
                  icon
                  class="mx-0"
                  v-on="on"
                  @click="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('dataTable.EDIT') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  id="delete"
                  icon
                  class="mx-0"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('dataTable.DELETE') }}</span>
            </v-tooltip>
          </v-row>
        </td>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ getFormat(item.createdAt) }}
      </template>
      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ getFormat(item.updatedAt) }}
      </template>
      <template
        v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        {{ pageStart }} - {{ pageStop }}
        {{ $t('dataTable.OF') }}
        {{ itemsLength }}
      </template>
      <template v-slot:no-data>
        {{ $t('dataTable.NO_DATA') }}
      </template>
      <template v-slot:no-results>
        {{ $t('dataTable.NO_RESULTS') }}
      </template>
    </v-data-table>
    <ErrorMessage />
    <SuccessMessage />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { getFormat, buildPayloadPagination } from '@/utils/utils.js'

  export default {
    metaInfo () {
      return {
        title: this.$store.getters.appTitle,
        titleTemplate: `${this.$t('users.TITLE')} - %s`,
      }
    },
    data () {
      return {
        searchInput: '',
        dataTableLoading: true,
        delayTimer: null,
        dialog: false,
        search: '',
        pagination: {},
        editedItem: {},
        defaultItem: {},
        fieldsToSearch: ['name', 'email', 'role', 'city', 'country', 'phone'],
      }
    },
    computed: {
      roles () {
        return [
          { name: this.$t('roles.ADMIN'), value: 'admin' },
          { name: this.$t('roles.USER'), value: 'user' },
        ]
      },
      allCities () {
        return this.$store.state.cities.allCities
      },
      formTitle () {
        return this.editedItem._id
          ? this.$t('dataTable.EDIT_ITEM')
          : this.$t('dataTable.NEW_ITEM')
      },
      headers () {
        return [
          {
            text: this.$i18n.t('dataTable.ACTIONS'),
            value: '_id',
            sortable: false,
            width: 100,
          },
          {
            text: this.$i18n.t('users.headers.NAME'),
            align: 'left',
            sortable: true,
            value: 'name',
          },
          {
            text: this.$i18n.t('users.headers.EMAIL'),
            align: 'left',
            sortable: true,
            value: 'email',
          },
          {
            text: this.$i18n.t('users.headers.ROLE'),
            align: 'left',
            sortable: true,
            value: 'role',
          },
          {
            text: this.$i18n.t('users.headers.VERIFIED'),
            align: 'left',
            sortable: true,
            value: 'verified',
          },
          {
            text: this.$i18n.t('users.headers.CITY'),
            align: 'left',
            sortable: true,
            value: 'city',
          },
          {
            text: this.$i18n.t('users.headers.COUNTRY'),
            align: 'left',
            sortable: true,
            value: 'country',
          },
          {
            text: this.$i18n.t('users.headers.PHONE'),
            align: 'left',
            sortable: true,
            value: 'phone',
          },
          {
            text: this.$i18n.t('common.CREATED'),
            align: 'left',
            sortable: true,
            value: 'createdAt',
          },
          {
            text: this.$i18n.t('common.UPDATED'),
            align: 'left',
            sortable: true,
            value: 'updatedAt',
          },
        ]
      },
      items () {
        return this.$store.state.adminUsers.users
      },
      totalItems () {
        return this.$store.state.adminUsers.totalUsers
      },
    },
    watch: {
      dialog (value) {
        return value ? true : this.close()
      },
      pagination: {
        async handler () {
          try {
            this.dataTableLoading = true
            await this.getUsers(
              buildPayloadPagination(this.pagination, this.buildSearch()),
            )
            this.dataTableLoading = false
          // eslint-disable-next-line no-unused-vars
          } catch (error) {
            this.dataTableLoading = false
          }
        },
        deep: true,
      },
      search () {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
          this.doSearch()
        }, 400)
      },
    },
    methods: {
      ...mapActions([
        'getAllCities',
        'getUsers',
        'editUser',
        'saveUser',
        'deleteUser',
      ]),
      getFormat (date) {
        window.__localeId__ = this.$store.getters.locale
        return getFormat(date, 'iii, MMMM d yyyy, h:mm a')
      },
      roleName (value) {
        return value === 'admin' ? this.$t('roles.ADMIN') : this.$t('roles.USER')
      },
      trueFalse (value) {
        return value
          ? '<i aria-hidden="true" class="v-icon mdi mdi-check green--text" style="font-size: 16px;"></i>'
          : '<i aria-hidden="true" class="v-icon mdi mdi-close red--text" style="font-size: 16px;"></i>'
      },
      async doSearch () {
        try {
          this.dataTableLoading = true
          await this.getUsers(
            buildPayloadPagination(this.pagination, this.buildSearch()),
          )
          this.dataTableLoading = false
        // eslint-disable-next-line no-unused-vars
        } catch (error) {
          this.dataTableLoading = false
        }
      },
      buildSearch () {
        return this.search
          ? { query: this.search, fields: this.fieldsToSearch.join(',') }
          : {}
      },
      editItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      async deleteItem (item) {
        try {
          const response = await this.$confirm(
            this.$t('common.DO_YOU_REALLY_WANT_TO_DELETE_THIS_ITEM'),
            {
              title: this.$t('common.WARNING'),
              buttonTrueText: this.$t('common.DELETE'),
              buttonFalseText: this.$t('common.CANCEL'),
              buttonTrueColor: 'red lighten3',
              buttonFalseColor: 'yellow',
            },
          )
          if (response) {
            this.dataTableLoading = true
            await this.deleteUser(item._id)
            await this.getUsers(
              buildPayloadPagination(this.pagination, this.buildSearch()),
            )
            this.dataTableLoading = false
          }
        // eslint-disable-next-line no-unused-vars
        } catch (error) {
          this.dataTableLoading = false
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        }, 300)
      },
      async save () {
        try {
          this.dataTableLoading = true
          // Updating item
          if (this.editedItem._id) {
            await this.editUser(this.editedItem)
            await this.getUsers(
              buildPayloadPagination(this.pagination, this.buildSearch()),
            )
            this.dataTableLoading = false
          } else {
            // Creating new item
            await this.saveUser({
              name: this.editedItem.name,
              email: this.editedItem.email,
              password: this.editedItem.password,
              role: this.editedItem.role,
              phone: this.editedItem.phone,
              city: this.editedItem.city,
              country: this.editedItem.country,
            })
            await this.getUsers(
              buildPayloadPagination(this.pagination, this.buildSearch()),
            )
            this.dataTableLoading = false
          }
          this.close()
          return
        // eslint-disable-next-line no-unused-vars
        } catch (error) {
          this.dataTableLoading = false
          this.close()
        }
      },
    },
    async mounted () {
      await this.getAllCities()
    },
  }
</script>

<style>
table.v-table {
  max-width: none;
}
</style>
